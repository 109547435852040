@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

.logo {
  width: 120px;
  height: 80px;
}

.slogan {
  display: inline-block;
  font-style: italic;
  position: relative;
  margin-left: 50px;
  /* margin-left: 25px;
  margin-top: 60px !important; */
  color: #0a0da0;
  font-weight: 600;
  font-family: 'Ubuntu', 'sans-serif' !important;
}

.moneyImg {
  width: 0px;
  height: 10px;
  position: absolute;
  top: -10px;
  right: 850px;
  opacity: 0.1;
}

.moneyImg>img {
  width: 800px;
}

@media only screen and (max-width: 1500px) {
  .moneyImg {
    right: 700px;
  }

  .moneyImg>img {
    width: 700px;
  }
}

@media only screen and (max-width: 1250px) {
  .moneyImg {
    right: 600px;
  }

  .moneyImg>img {
    width: 600px;
    height: 180px;
  }
}

@media only screen and (max-width: 1200px) {
  .moneyImg {
    right: 500px;
  }

  .moneyImg>img {
    width: 500px;
    height: 180px;
  }
}

@media only screen and (max-width: 1100px) {
  .moneyImg {
    right: 500px;
  }

  .moneyImg>img {
    width: 500px;
    height: 180px;
  }
}

@media only screen and (max-width: 1000px) {
  .moneyImg {
    right: 300px;
  }

  .moneyImg>img {
    width: 300px;
    height: 180px;
  }
}

@media only screen and (max-width: 500px) {
  .moneyImg {
    right: 220px;
  }

  .moneyImg>img {
    width: 350px;
    height: 180px;
  }
}

.navbar {
  /* background-image: url(../img/navBkg.svg);
  background-repeat: round !important;
  background-repeat: no-repeat; */
  background-color: #fff;
  border-bottom: 3px solid blue;
  box-shadow: 0 3px 10px rgba(26, 16, 203, 0.2);
  overflow: hidden;
}

.nav-item {
  font-size: 1.3rem;
  color: #0a0da0 !important;
  margin-right: 25px;
  background-image: linear-gradient(to right,
      #031293,
      #11048e 50%,
      rgb(12, 20, 78) 50%);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
  width: fit-content;
  font-family: 'Ubuntu', 'sans-serif' !important;

}

.nav-item:before {
  content: '';
  background: #2d30fd;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.nav-item:hover {
  background-position: 0;
}

.nav-item:hover::before {
  width: 100%;
}

.logOutBtn {
  font-weight: bold;
  color: #000;
  background-color: #fff;
  padding: 5px;
}

.lajm {
  display: inline-block;
}

.n1 {
  position: absolute;
  width: 50px;
  height: 50px;
  opacity: 0.04;
  z-index: -1;
}

.n1:nth-child(1) {
  position: absolute;
  top: -30px;
  right: -170px;
  width: 120px;
  height: 100px;
}

.n1:nth-child(2) {
  position: absolute;
  top: 30px;
  right: -15px;
  width: 100px;
  height: 80px;
}

.n1:nth-child(3) {
  position: absolute;
  top: -30px;
  left: -140px;
  width: 140px;
  height: 120px;
}

.n1:nth-child(4) {
  position: absolute;
  top: -10px;
  right: 130px;
  width: 140px;
  height: 100px;
}

.n1:nth-child(5) {
  position: absolute;
  top: -30px;
  right: 320px;
  width: 100px;
  height: 80px;
}

.n1:nth-child(6) {
  position: absolute;
  top: 35px;
  right: 480px;
  width: 100px;
  height: 80px;
}

.cur {
  background-color: #000;
}

.lang {
  position: absolute;
  right: 10px;
  margin: 5px -5px;
  width: 75px;
  height: 35px;
  padding: 0px;
}


.langOption {
  background-color: #fff !important;
}

@media only screen and (max-width: 995px) {
  .slogan {
    margin-left: 25px;
  }
}

@media only screen and (max-width: 770px) {
  .logOutBtn {
    display: block;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 560px) {
  .lajm {
    display: block;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 500px) {
  .n1:nth-child(1) {
    position: absolute;
    top: -25px;
    right: -15px;
    width: 80px;
    height: 70px;
  }

  .n1:nth-child(2) {
    position: absolute;
    top: 35px;
    right: 45px;
    width: 80px;
    height: 70px;
  }

  .n1:nth-child(4) {
    position: absolute;
    top: -20px;
    right: 110px;
    width: 80px;
    height: 70px;
  }

  .n1:nth-child(5) {
    position: absolute;
    top: 50px;
    right: 180px;
    width: 60px;
    height: 50px;
  }

  .n1:nth-child(6) {
    position: absolute;
    top: -10px;
    right: 220px;
    width: 70px;
    height: 50px;
  }
}