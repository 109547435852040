@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

.curBlej {
  display: inline-block;
  margin-right: 100px;
}

.curBlej>label {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  width: 200px;
  margin-left: 20px;
}

.curShes {
  display: inline-block;
}

.curShes>label {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  width: 200px;
  margin-left: 20px;
}

input {
  width: 75px !important;
  border: 2px solid black !important;
  width: 150px !important;
}

.vendosKurs {
  margin-bottom: 35px
}

.vendosKursTable {
  margin-left: 300px;
  min-width: 360px;
}

.lajmSection {
  margin-left: 350px;
}

.lajmSection>div>label {
  margin-left: 20px;
}

.lajmSection>div>input {
  width: 500px !important;
}

@media only screen and (max-width: 1200px) {
  .vendosKursTable {
    margin-left: 200px;
  }
}

@media only screen and (max-width: 1000px) {
  .vendosKursTable {
    margin-left: 150px;
  }

  .lajmSection {
    margin-left: 150px;
  }

}

@media only screen and (max-width: 700px) {
  .vendosKursTable {
    margin-left: 100px;
  }

  .lajmSection {
    margin-left: 100px;
  }

  .lajmSection>div>input {
    width: 350px !important;
  }
}

@media only screen and (max-width: 500px) {
  .vendosKursTable {
    margin: 10px;
  }

  .lajmSection {
    margin-left: 10px;
  }

  .lajmSection>div>input {
    width: 300px !important;
  }
}