@import url('https://fonts.googleapis.com/css2?family=Public+Sans&display=swap');

body {
  background-color: #fbfaf7;
  margin-bottom: 150px;
}

.ratesTable {
  margin-top: 225px;
  width: 60% !important;
  min-width: 320px;
  box-shadow: 6px 6px 12px #e1e1e2;
}

.ratesTable>div:nth-child(1) {
  background-image: url(../img/economyImg.jpg);
  background-size: cover;
  color: #fff;
}

.ratesRow {
  background-color: #dce2fe;
  text-align: center;
  height: 55px !important;
  padding: 10px;
  margin-right: -25px;
  /* ??????  */
  margin-left: -25px;
  border-bottom: 1px solid #bad2fd;
}

.bckgr {
  background-image: url(../img/economyImg.jpg);
  background-size: cover;
  color: #fff;
}

.ratesRow:hover {
  background-color: #c6d0ff;
  cursor: pointer;
}

.ratesRow:nth-child(1):hover {
  border: none !important;
}

.ratesRow>div:nth-child(1) {
  text-align: left;
}

.curImg {
  display: inline-block;
  height: 25px;
  width: 40px;
}

.curImgChf {
  width: 32px;
  margin-left: 3px;
}

.ratesRow>div>img:nth-child(3) {
  width: 20px !important;
}

.curName {
  position: relative;
  left: 50px;
  top: -25px;
}

.curValue {
  font-weight: bold;
  color: #160075;
}

.container:nth-child(4):first-child {
  width: 20px;
}

.ratesRow>div>p {
  font-family: 'Public Sans', sans-serif;
  font-size: 20px;
}

.homeSideBtn {
  display: block;
  margin-top: 450px;
  background-color: #e9d117;
  padding: 20px;
  border: none;
  width: 200px;
  font-size: 22px;
  font-weight: bolder;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  color: #fff;
  box-shadow: 6px 6px 12px #e1e1e2;
}

.homeSideBtn {
  animation: fadeInAnimation ease 6s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}



@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.homeSideBtn:hover {
  background-color: #fff;
  color: #e9d117;
}

.side>a:nth-child(2)>.homeSideBtn {
  margin-top: 50px;
}

.side>a {
  text-decoration: none;
}

@media only screen and (max-width: 770px) {
  .homeSideBtn {
    margin: auto;
    margin-top: 85px;
  }
}

@media only screen and (min-width: 450px) {
  .ratesRow {
    height: 48px !important;
  }

  .shesim {
    width: 150px !important;
  }
}

.shesim {
  margin-left: 30px;
  display: flex;
  justify-content: left;
}

@media only screen and (max-width: 1400px) {
  .shesim {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .shesim {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 1000px) {
  .shesim {
    margin-left: 0px;
  }

  .blejme {
    margin-right: -10px;
  }

  .valuta {
    margin-left: -12px !important;
  }
}

@media only screen and (max-width: 450px) {
  .shesim {
    width: 100px;
  }

  .blejme {
    margin-right: -10px;
  }

  .valuta {
    margin-left: -12px !important;
  }
}

.perditesuar {
  opacity: 0.5;
  font-style: italic;
  padding: 5px;
}

/* 
@media only screen and (max-width: 650px) {
  .ratesTable {
    width: 270px !important;
    left: 20px;
    right: 20px;
  }

  .ratesTable {}
}

@media only screen and (max-width: 500px) {
  .ratesTable {
    width: 270px !important;
    left: 20px;
    right: 20px;
  }

  .ratesTable {
    margin-left: 10px;
  }
} */