@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

.accordion {
  width: 70%;
  margin: auto;
  min-width: 350px;
}

select,
input {
  background-color: #efc21f;
  width: 150px;
  margin: 10px 20px;
  padding: 10px;
  font-size: 20px;
  color: #fff;
  font-weight: bolder;
  border: solid #efc21f;
}

.reset {
  background-color: white;
  margin-left: 20px;
  margin-top: 20px;
  border: 3px solid #929292;
  font-family: 'Ubuntu', sans-serif;
}

.res {
  background-color: #e6e9f9;
  width: fit-content;
  min-width: 150px;
  margin: 10px 20px;
  padding: 10px;
  font-size: 25px;
  color: #0a0da0;
  font-weight: bolder;
  border: 4px solid #0a0da0;
}

option {
  background-color: #fff;
  color: #e9d117;
  font-size: 16px;
  font-weight: bold;
}

option:hover {
  background-color: #efc21f;

}

label {
  display: block;
  font-family: 'Ubuntu', sans-serif;
  font-size: 25px;
  font-weight: bolder;
  color: #0a0da0;
}

input {
  display: block;
  border: none !important;
}

@media only screen and (max-width: 600px) {
  .accordion {
    width: 90%;
  }
}

@media only screen and (max-width: 860px) {
  .accordion {
    width: 80%;
  }
}