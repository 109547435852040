@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

.title {
  font-family: 'Ubuntu', sans-serif;
  font-size: 30px;
  margin: 30px 0px;
  color: rgb(1, 65, 216);
  text-shadow: 2px 2px 2px #e9e384;
}

.contactBox>div>p:nth-child(1) {
  color: #fff !important;
}

.text {
  font-family: 'Ubuntu', sans-serif;
  font-size: 18px;
}

.textVend {
  font-family: 'Ubuntu', sans-serif;
  font-size: 18px;
  font-weight: bold;
}

.container {
  position: relative;
}

.center {
  margin: 0;
  margin-top: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: black;
  color: white;
  font-weight: bold;
  padding: 15px;
  width: 200px;
}

.slider {
  width: 410px !important;
  height: 400px !important;
  margin-top: 275px;
  margin-left: 50px;
}

.sliderImg {
  width: 365px !important;
  height: 350px !important;
}

.sliderTxt {
  margin-left: 30px;
  margin-bottom: -15px !important;
}

.contactBox {
  margin-top: 50px;
  background-color: #280882;
  color: #fff;
  padding: 20px;
  display: inline-block;
  border: 4px ridge rgb(224, 222, 222);
}

.contactBox>div:nth-child(1) {
  display: inline-block;
  padding-right: 100px;
}

.contactBox>div:nth-child(2) {
  display: inline-block;
  padding-right: 100px;
}

.contactBox>div:nth-child(3) {
  display: inline-block;
}

.nr {
  padding-left: 15px;
}

.contact>img {
  display: inline-block;
}

.contact>p {
  display: inline-block;
}

.contactMedia {
  width: 25px;
  height: 25px;
  border-radius: 30%;
}

.hide {
  color: #280882;
}

.loginContainer {
  width: 350px !important;
  margin-top: -50px !important;
}

.loginInput {
  width: 300px !important;
  margin: auto !important;
}

.loginBtn {
  width: 50% !important;
  margin-top: 10px;
  margin-left: 25%;
  font-size: 18px;
  font-weight: bolder;
}

@media only screen and (max-width: 990px) {
  .slider {
    margin: 30px 0px;
  }
}

@media only screen and (max-width: 500px) {
  .slider {
    width: 350px !important;
    margin-left: -5px;
    padding-left: 5px;
  }


}